const dl = 'dataLayer';
window[dl] = window[dl] || [];

const tagId = process.env.GOOGLE_TAG_MANAGER || null;
const analyticsId = process.env.GOOGLE_ANALYTICS || null;

window.addEventListener('load', function () {

  void 0 === window._axcb && (window._axcb = []);
  window._axcb.push(function (axeptio) {

    axeptio.on('cookies:complete', function (choices) {
      if (choices.googletagmanager !== null && tagId !== null) {
        loadGtm(choices.googletagmanager);
      }

      if (choices.google_analytics !== null && analyticsId !== null) {
        loadGtag(choices.google_analytics, choices.$$googleConsentMode);
      }
    });

    axeptio.on('cookies:consent', function (consent) {
      if (consent.choices.googletagmanager === false || consent.choices.google_analytics === false) {
        location.reload();
      }
    });
  });

});

const loadGtm = (accepted) => {

  if (accepted && document.getElementById('axeptio-gtm') === null) {

    window[dl].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.async = true;
    const dLink = dl !== 'dataLayer' ? '&l=' + dl : '';
    script.src = 'https://www.googletagmanager.com/gtm.js?id=' + tagId + dLink;
    script.type = 'text/javascript';
    script.id = 'axeptio-gtm';
    head.appendChild(script);

    let noScript = document.createElement('noscript');
    noScript.textContent = '<iframe src="https://www.googletagmanager.com/ns.html?id=' + tagId + '"\n' +
      '          height="0" width="0"\n' +
      '          style="display:none;visibility:hidden"></iframe>';

    head.appendChild(noScript);

  }
};
const loadGtag = (accepted, consentMode) => {
  if (accepted && document.getElementById('axeptio-gtag') === null) {

    function gtag() {window[dl].push(arguments);}

    gtag('js', new Date());

    // check in axeptio
    gtag('consent', 'update', {
      'ad_storage': consentMode.ad_storage ?? 'denied',
      'analytics_storage': consentMode.analytics_storage ?? 'denied',
      'ad_user_data': consentMode.ad_user_data ?? 'denied',
      'ad_personalization': consentMode.ad_personalization ?? 'denied',
      'wait_for_update': 500,
    });

    let script = document.createElement('script');
    let head = document.getElementsByTagName('head')[0];
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + analyticsId;
    script.type = 'text/javascript';
    script.id = 'axeptio-gtag';
    head.appendChild(script);

    gtag('config', analyticsId);

  }
};